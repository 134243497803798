<template>
  <div class="app-container" id="mappingWrap">
    <ul class="headerClass">
<!--      <li class="headerLiClass">-->
<!--        <span style="border-left: 5px solid #0073E9;padding-left: 10px;">药品元数据映射</span>-->
<!--      </li>-->
<!--      <li class="headerLiClass">-->
<!--        <ul class="firstMenuChoice">-->
<!--          <li v-for="(item,index) in firstMenulist" :key="index" :class="firstFlag==index?'firstmenuLiActive':''" @click="chooseFirstMenu(index)"><a :href="item.href">{{item.name}}</a></li>-->
<!--        </ul>-->
<!--      </li>-->
      <li class="headerLiClass" v-if="firstFlag === 0">
        <ul class="menuChoice">
          <li v-for="(item,index) in menulist" :key="index" :class="flag==index?'menuLiActive':''" @click="chooseMenu(index)"><a :href="item.href">{{item.name}}</a></li>
        </ul>
      </li>
    </ul>
<!--    选择代码进行匹配-->
    <div v-if="firstFlag === 0">
      <ul class="headerClass drugElementData">
<!--        <li class="headerLiClass">-->
<!--          <span style="border-left: 5px solid #0073E9;padding-left: 10px;">机构药品元数据</span>-->
<!--        </li>-->
        <li class="headerLiClass" v-if="firstFlag === 0">
          <el-form :model="queryParams" ref="queryForm" :inline="true">
            <el-form-item label='' prop="searchValue">
              <el-input v-model="queryParams.searchValue"
                        placeholder="输入类型代码/类型名称"
                        clearable
                        style="width: 200px;height: 36px!important;line-height: 36px;"
                        @keyup.native="handlerKeyChange($event)"/>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" icon="el-icon-search" size="small" @click="handleQuery()">查询</el-button>
            </el-form-item>
          </el-form>
        </li>
        <li class="headerLiClass">
          <ul class="thirdMenuChoice">
            <li v-for="(item,index) in thirdMenulist" :key="index" :class="thirdFlag==index?'thirdmenuLiActive':''" @click="chooseThirdMenu(index)"><a :href="item.href">{{item.name}}</a></li>
          </ul>
        </li>
<!--        <li class="headerLiClass" style="float: right;">-->
<!--          <el-button type="primary" @click="handleMappingClick">映射</el-button>-->
<!--        </li>-->
      </ul>

      <section v-if="this.isMatchNeedDef !== null" style="margin-bottom: 10px;">
        <el-link
          v-show="this.isMatchNeedDef === true"
          type="danger"
          @click="getDefList"
          v-loading.fullscreen.lock="isMatchLoading">
          当前上传数据存在多对一的映射状态，请选择默认映射值
        </el-link>
        <el-link
          v-show="this.isMatchNeedDef === false"
          type="primary"
          @click="getDefList"
          v-loading.fullscreen.lock="isMatchLoading">
          维护映射默认值
        </el-link>
      </section>
      <el-row :gutter="20">
        <el-col :span="18">
          <el-table
            ref="udpTable"
            v-loading="loading"
            border
            stripe
            :height="tableHeight"
            :header-cell-style="{'text-align':'center'}"
            :cell-style="{'text-align':'center'}"
            :data="mappingList"
            @row-click="rowClick"
            highlight-current-row>
            <!--          label="中西草生物制品-类型代码"-->
            <el-table-column
              prop="code"
              :label="labelName + '-类型代码'"
              width="180">
            </el-table-column>
            <el-table-column
              prop="name"
              :label="labelName + '-类型名称'">
            </el-table-column>
            <el-table-column
              label="映射状态">
              <template slot-scope="scope">
                <span v-if="scope.row.mappingStatus === '已映射'" class="yys">{{ scope.row.mappingStatus }}</span>
                <span v-else-if="scope.row.mappingStatus === '未映射'" class="wys">{{ scope.row.mappingStatus }}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="mappingIdStr"
              label="映射ID">
            </el-table-column>
            <el-table-column
              prop="mappingName"
              label="映射值">
            </el-table-column>
            <el-table-column
              label="操作">
              <template slot-scope="scope">
                <el-button
                  v-if="scope.row.mappingStatus === '未映射' || scope.row.isNeedMappinig === true"
                  size="mini"
                  type="text"
                  @click.stop="handleMapping(scope.row)"
                >映射</el-button>
<!--                v-if="scope.row.mappingStatus === '已映射' || isNeedMappinig=== false"-->
                <el-button
                  v-else
                  size="mini"
                  type="text"
                  @click.stop="handleCancelMapping(scope.row)"
                >取消映射</el-button>
              </template>
            </el-table-column>
            <template slot="empty" v-if="isResult === false">
              <img src="@/assets/images/no-booking.svg" alt="">
              <p>暂无数据</p>
            </template>
            <template slot="empty" v-else>
              <img src="@/assets/images/no-left-data.svg" alt="">
              <p>未查询到结果，请修改查询条件重试！</p>
            </template>
          </el-table>
        </el-col>
        <el-col :span="6">
          <el-row class="platformDkbWrap" :style="conheight">
            <el-col class="platformDkb">平台药品知识库</el-col>
            <el-col class="platformDkb_content">
              <div class="choiceTree">
                <el-input v-model="filterText" placeholder="请输入内容">
                  <i slot="prefix" class="el-input__icon el-icon-search"></i>
                </el-input>
              </div>
              <div class="treeContent">
                <el-tree
                  ref="tree"
                  :data="cwMedicineIdentification"
                  show-checkbox
                  node-key="conceptId"
                  empty-text="暂无数据！"
                  :props="defaultProps"
                  check-strictly
                  @node-click="handleElNodeClick"
                  @check="currentChecked"
                  :filter-node-method="filterNode"
                ></el-tree>
<!--                @check-change="handleCheckChange"-->
              </div>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12" class="stateContent" style="padding-top: 10px;">
          <span>上传时间：{{ uploadTime }}</span>
          <span>状态：<i class="stateResult">{{ statusStr }}</i></span>
          <span>上传方式：<i class="uploadMethod">{{ uploadTypeStr }}</i></span>
        </el-col>
        <el-col :span="12">
          <pagination
            v-show="total>0"
            :total="total"
            :page.sync="queryParams.pageNum"
            :limit.sync="queryParams.pageSize"
            :isSmall="true"
            @pagination="getList"
          />
        </el-col>
      </el-row>
    </div>
<!--    v-dialogDrag-->
    <el-dialog
      :visible.sync="matchDefDialogVisible"
      title="默认映射值设置"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      width="80%"
      :before-close="matchDefHandleClose">
      <div class="approvalWrapTop">
        <div v-for="(item, index) in matchDefListOptions">
          <el-radio-group v-model="ids[index]" @change="handleRadioChanges" style="width: 100%">
          <el-table
            :data="item"
            border
            stripe
            :header-cell-style="{'text-align':'center'}"
            :cell-style="{'text-align':'center'}"
            style="margin:10px 0px;"
            ref="commnonName"
            :span-method="(param)=>arraySpanMethod(param,index)"
            @current-change="clickChange">
            <el-table-column prop="domainName" label="类型"></el-table-column>
            <el-table-column prop="code" label="代码"></el-table-column>
            <el-table-column prop="name" label="名称"></el-table-column>
            <el-table-column label="映射状态">
              <template slot-scope="scope">
                <span v-if="scope.row.mappingStatus === '已映射'" class="yys">{{ scope.row.mappingStatus }}</span>
                <span v-else-if="scope.row.mappingStatus === '未映射'" class="wys">{{ scope.row.mappingStatus }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="mappingIdStr" label="映射ID"></el-table-column>
            <el-table-column prop="mappingName" label="映射值"></el-table-column>
            <el-table-column label="设为默认值">
              <!--            orgDefFlag-->
              <template scope="scope">
                <el-radio :label="scope.row.id"><i></i></el-radio>
              </template>
            </el-table-column>
          </el-table>
          </el-radio-group>
        </div>
      </div>
      <el-row class="approvalWrapBottom">
        <el-col :span="24" style="text-align: center;">
          <el-button type="primary" @click="submitMatchDef" class="successClass">确定</el-button>
          <el-button @click="failMatchDefCancel" class="failClass">取消</el-button>
        </el-col>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
import {
  list,
  bind,
  unbind,
  matchNeedDef,
  defList,
  setMatchDef
} from "@/api/term/drugElementMapping"
import { getConcept,getFormConceptTree, getAtcAndCpdConceptTree } from '@/utils/concept'
import conceptConst from '@/const/conceptConst'
import { treeselect as menuTreeselect } from "@/api/system/menu";
import { head } from "@/api/dm/uploadDocumentPretreatment"
import { getConceptsByDomainCode } from '@/api/dkm/preparationSpecification'
const typeOptions = [
  {name: '西药', value: 0},
  {name: '中成药', value: 1},
  {name: '草药', value: 2},
]
const matchingOptions = [
  {name: '全部', value: 1},
  {name: '未做', value: 2},
  {name: '做过', value: 3},
  {name: '不做', value: 4}
]
const signOptions = [
  {name: '精准匹配-同（同厂商）', value: 0},
  {name: '精准匹配-同（同厂商）', value: 1}
]
const matchedOptions = [
  {name: '生产厂商不同', value: 0},
  {name: '规格不同', value: 1},
  {name: '剂型不同', value: 2},
  {name: '其他', value: 3}
]
const unmatchedOptions  = [
  {name: '名称不明', value: 0},
  {name: '剂型不明', value: 1},
  {name: '规格不明', value: 2},
  {name: '库中无此药', value: 3},
  {name: '其他', value: 4}
]
const drugSignOptions = [
  {name: '药品包装', value: 1},
  {name: '包装材料', value: 2},
  {name: '药品', value: 3},
  {name: '制剂规格', value: 4},
  {name: '制剂', value: 5},
  {name: '药物', value: 6}
]
export default {
  name: "drugMetadataMatchingCodeMapping",
  data() {
    return {
      filterText: '',
      radio: '1',
      value: true,
      labelName: '中西草生物制品',
      // 表格高度
      tableHeight: window.innerHeight - 100 - 100 - 47.5,
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        searchValue: '',
        orgId: '',
        domainCode: 'dkb_drug_classify',
        status: ''
      },
      isResult: false,
      conceptConst,
      // 总条数
      total: 0,
      // 映射表格数据
      mappingList: [],
      // 弹出层标题
      title: "",
      loading: false,
      statusStr: '',
      uploadTime: '',
      uploadTypeStr: '',
      activeName: '1',
      firstFlag: 0,
      firstMenulist:[
        {
          value: 0,
          name: '代码'
        },
        {
          value: 1,
          name: '药品'
        },
      ],
      statusMappping: '',
      thirdFlag: 0,
      thirdMenulist:[
        {
          value: 0,
          name: '全部'
        },
        {
          value: 1,
          name: '已映射'
        },
        {
          value: 2,
          name: '未映射'
        }
      ],
      domainCode: 'dkb_drug_classify',
      flag: 0,
      menulist:[
        {
          value: 0,
          name: '中西草生物制品类型'
        },
        {
          value: 1,
          name: '剂型'
        },
        {
          value: 2,
          name: '高危药品分级'
        },
        {
          value: 3,
          name: '药品分类'
        },
        {
          value: 4,
          name: '抗菌药分级'
        },
        {
          value: 5,
          name: '精麻毒放标志'
        },
        // {
        //   value: 6,
        //   name: '单位'
        // },
        {
          value: 6,
          name: '规格单位'
        },
        {
          value: 7,
          name: '包装单位'
        }
      ],
      // 菜单列表
      menuOptions: [],
      defaultProps: {
        children: "children",
        label: "conceptVal",
        // disabled: this.disabledFn
      },
      conheight: {
        height: window.innerHeight - 100 - 100 - 47.5 +'px'
      },
      /*中西药标识符下拉列表 */
      cwMedicineIdentification: [],
      orgId: '',
      recordId: '',
      conceptId: [],
      id: '', // 机构的id
      selectOrg: {
        orgsid: []
      },
      isLookShow: false, // 是否是查看
      isMatchNeedDef: false, // 是否需要进行1-n默认关系确认
      matchDefDialogVisible: false,
      matchDefListOptions: [],
      tableRadio: '',
      ids: [],
      isMatchLoading: false,
      //spanArr用于存放每一行记录的合并数
      spanArr: [],
      currentLineTableData: {}
      // isNeedMappinig: false
    };
  },
  created() {
    this.getMenuTreeselect();
    this.init().then(result=>{
    })
    const recordId = this.$route.query && this.$route.query.recordId
    if (recordId) {
      this.recordId = recordId
      this.headMessage()
    }
    // console.log(recordId)
    const isLookShow = this.$route.query && this.$route.query.isLookShow
    if (isLookShow) {
      this.isLookShow = Boolean(isLookShow)
    } else {
      this.isLookShow = false
    }
    // console.log(this.isLookShow)
    const orgId = this.$route.params && this.$route.params.orgId
    // console.log(orgId)
    if (orgId) {
      this.orgId = orgId
      this.init().then(result => {
        this.getList()
        this.matchNeedDefFn()
      })

    } else {
      this.init().then(result=>{
      })
    }
  },
  activated() {
    this.getMenuTreeselect();
    this.init().then(result=>{
    })
    const recordId = this.$route.query && this.$route.query.recordId
    if (recordId) {
      this.recordId = recordId
      this.headMessage()
    }
    // console.log(recordId)
    const isLookShow = this.$route.query && this.$route.query.isLookShow
    if (isLookShow) {
      this.isLookShow = Boolean(isLookShow)
    } else {
      this.isLookShow = false
    }
    // console.log(this.isLookShow)
    const orgId = this.$route.params && this.$route.params.orgId
    // console.log(orgId)
    if (orgId) {
      this.orgId = orgId
      this.init().then(result => {
        this.getList()
        this.matchNeedDefFn()
      })

    } else {
      this.init().then(result=>{
      })
    }
  },
  watch: {
    filterText(val) {
      // console.log(val)
      this.$refs.tree.filter(val)
    }
  },
  mounted() {
    // 获取高度值 （内容高+padding+边框）
    let height = this.$refs.udpTable.offsetHeight
    console.log(height)
    const that = this
    window.onresize = () => {
      return (() => {
        that.tableHeight = window.innerHeight - 100 - 100 - 47.5
        that.conheight.height = window.innerHeight - 100 - 100 - 47.5 + 'px'
      })()
    }
  },
  methods: {
    /* 剂型--下拉搜索过滤*/
    // typeFilter(query) {
    //   if (query !== '') {
    //     this.preparationTypeList = this.preparationTypeDict.filter(item => {
    //       return item.conceptVal.toLowerCase()
    //           .indexOf(query.toLowerCase()) > -1
    //         || item.spell.toLowerCase()
    //           .indexOf(query.toLowerCase()) > -1
    //         || item.wubi.toLowerCase()
    //           .indexOf(query.toLowerCase()) > -1
    //     })
    //
    //   } else {
    //     this.preparationTypeList = this.preparationTypeDict
    //   }
    // },
    filterNode(query,data) {
      // console.log(query, data)
      if (query !== '') {
        let flag= (data.conceptVal && data.conceptVal.toLowerCase()
            .indexOf(query.toLowerCase()) > -1)
          || (data.spell && data.spell.toLowerCase()
            .indexOf(query.toLowerCase()) > -1)
          ||(data.wubi && data.wubi.toLowerCase()
            .indexOf(query.toLowerCase()) > -1)
        //概念别名过滤
        if(data.aliasList){
          data.aliasList.some(aliasItem=>{
            if((aliasItem.conceptName && aliasItem.conceptName.toLowerCase().indexOf(query.toLowerCase())>-1)
              || (aliasItem.spell && aliasItem.spell.toLowerCase().indexOf(query.toLowerCase())>-1)
              || (aliasItem.wubi && aliasItem.wubi.toLowerCase().indexOf(query.toLowerCase())>-1)
            ){
              flag=true
              return true
            }
          })
        }
        return flag

      } else {
        return data
      }
      // console.log(value,data)
      // if(!value) return true;
      // return data.conceptVal.indexOf(value) !== -1;
    },
    // 查看树结构时候禁用
    disabledFn() {
      if (this.isLookShow === true) {
        return true
      } else {
        return false
      }
    },
    /*初始化--加载字典等*/
    async init() {
      this.cwMedicineIdentification = [] // 清空
      if (this.flag === 0) {
        //获取中西药物标识数据字典
        await getConcept(conceptConst.DKB_DRUG_CLASSIFY, 0).then(result => {
          // console.log(this.cwMedicineIdentification)
          this.cwMedicineIdentification = result
        })
      } else if (this.flag === 1) {
        /*获取剂型字典*/
        getFormConceptTree().then(result => {
          // console.log(result)
          this.cwMedicineIdentification = result
        })
      } else if (this.flag === 2) {
        /*获取高危药品分类字典*/
        await getConcept('pub_high_risk_1_class', 0).then(result => {
          this.cwMedicineIdentification = result
        })
      } else if (this.flag === 3) {
        /*获取药品分类字典*/
        getAtcAndCpdConceptTree().then(result => {
          // console.log(result)
          this.cwMedicineIdentification = result
        })
      } else if (this.flag === 4) {
        /*获取抗菌药分类字典*/
        await getConcept('pub_antibac_1_class', 0).then(result => {
          this.cwMedicineIdentification = result
        })
      } else if (this.flag === 5) {
        /*获取精麻毒放标志字典*/
        await getConcept('pub_special_drug_class', 0).then(result => {
          this.cwMedicineIdentification = result
        })
      // } else if (this.flag === 6) {
      //   /*获取单位字典*/
      //   let obj = {}
      //   obj.domainCode = 'pub_mass_unit,pub_volume_unit,pub_bq_unit,pub_length_unit,pub_spec_pkg_unit,pub_thermal_unit,pub_active_unit'
      //   obj.includeSon = 0
      //   let res = await getConceptsByDomainCode(obj) // 剂量单位（规格新增下拉数据集）
      //   this.cwMedicineIdentification = res.data
      } else if (this.flag === 6) { //规格单位
        /*获取单位字典*/
        let obj = {}
        obj.domainCode = 'pub_mass_unit,pub_volume_unit,pub_bq_unit,pub_length_unit,pub_spec_pkg_unit,pub_thermal_unit,pub_active_unit'
        obj.includeSon = 0
        let res = await getConceptsByDomainCode(obj)
        this.cwMedicineIdentification = res.data
      } else if (this.flag === 7) { // 包装单位
        /*获取单位字典*/
        let obj = {}
        obj.domainCode = 'pub_mass_unit,pub_volume_unit,pub_bq_unit,pub_length_unit,pub_spec_pkg_unit,pub_thermal_unit,pub_active_unit'
        obj.includeSon = 0
        let res = await getConceptsByDomainCode(obj)
        this.cwMedicineIdentification = res.data
      }

    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.isResult = true
      this.queryParams.pageNum = 1;
      this.getList();
    },
    /** 映射列表查询 */
    getList(singleId) {
      this.loading = true;
      this.queryParams.domainCode = this.domainCode
      this.queryParams.status = this.statusMappping
      this.queryParams.orgId = this.orgId
      list(this.queryParams).then(
        response => {
          // // console.log(response.data.rows)
          this.mappingList = response.data.rows;
          this.mappingList.forEach((item) => {
            item.isNeedMappinig = false
          })
          this.total = response.data.total;
          this.currentLineTableData = {} // 刷新table清空选中项
          this.loading = false;
          // console.log(singleId)
          if(singleId) {
            response.data.rows.forEach((item) => {
              if (item.code === singleId) {
                if (item.mappingStatus === '已映射' ) {
                  this.$refs.tree.setCheckedKeys([item.mappingId]);
                } else if (item.mappingStatus === '未映射' ) {
                  // let id = item.id
                  // this.id = id
                  this.$refs.tree.setCheckedKeys([]);
                }
              }
            })
          } else {
            this.$refs.tree.setCheckedKeys([]);
          }
        }
      );
    },
    handleClick(tab, event) {
      // console.log(tab, event);
    },
    // chooseFirstMenu(index) {
    //   this.firstFlag = index
    //   if (this.firstFlag === 1) {
    //     this.handleDrugQuery()
    //   }
    // },
    chooseMenu(index) {
      // 清查询数据
      this.queryParams = {
        pageNum: 1,
          pageSize: 10,
          searchValue: '',
          orgId: '',
          domainCode: 'dkb_drug_classify',
          status: ''
      }
      this.filterText = ''
      this.flag = index
      if (this.flag === 0) { // 中西草生物制品类型
        this.domainCode = 'dkb_drug_classify'
        this.labelName = '中西草生物制品'
      } else if (this.flag === 1) { // 剂型
        this.domainCode = 'dkb_form'
        this.labelName = '剂型'
      } else if (this.flag === 2) { // 高危药品分级
        this.domainCode = 'pub_high_risk_1_class'
        this.labelName = '高危药品分级'
      } else if (this.flag === 3) { // 药品分类
        this.domainCode = 'pub_atc_cpd_class'
        this.labelName = '药品分类'
      } else if (this.flag === 4) { // 抗菌药分级
        this.domainCode = 'pub_antibac_1_class'
        this.labelName = '抗菌药分级'
      } else if (this.flag === 5) { // 精麻毒放标志
        this.domainCode = 'pub_special_drug_class'
        this.labelName = '精麻毒放标志'
      // } else if (this.flag === 6) { // 单位
      //   this.domainCode = 'pub_measure_unit'
      //   this.labelName = '单位'
      } else if (this.flag === 6) { // 规格单位
        this.domainCode = 'pub_spec_unit'
        this.labelName = '规格单位'
      } else if (this.flag === 7) { // 包装单位
        this.domainCode = 'pub_pkg_unit'
        this.labelName = '包装单位'
      }
      this.selectOrg = {
        orgsid: []
      }
      this.init()
      this.getList()
      // this.matchNeedDefFn()
    },
    chooseThirdMenu(index) {
      this.thirdFlag = index
      if (this.thirdFlag === 0) { // 全部
        this.statusMappping = ''
      } else if (this.thirdFlag === 1) { // 已映射
        this.statusMappping = 1
      } else if (this.thirdFlag === 2) { // 未映射
        this.statusMappping = 2
      }
      this.getList()
    },
    /** 查询菜单树结构 */
    async getMenuTreeselect() {

      let response = await menuTreeselect()
      if (response.code === 200) {
        // console.log(response)
        this.menuOptions = [];
        const menu = { menuId: 0, menuName: '主类目', children: [] };
        menu.children = this.handleTree(response.data, "menuId");
        // // console.log(menu)
        // // console.log(menu.children)
        this.menuOptions = menu.children
        // console.log(this.menuOptions)
      }
    },
    // 根据记录ID获取记录头信息
    async headMessage() {
      // console.log(this.recordId)
      let res = await head(this.recordId)
      this.statusStr = res.data.statusStr
      this.uploadTime = res.data.uploadTime
      this.uploadTypeStr = res.data.uploadTypeStr
      // console.log(res)
    },
    // 映射
    async handleMapping(row) {
      await this.headMessage()
      if (this.statusStr === '已驳回') {
        this.$message.warning('当前数据已被驳回，请返回并检查状态！')
      } else if (this.statusStr === '已撤回') {
        this.$message.warning('当前数据已被撤回，请返回并检查状态！')
      } else if (this.statusStr === '已终止') {
        this.$message.warning('当前数据已被终止，请返回并检查状态！')
      } else {
        // // console.log(row)
        // let id = row.code
        // this.id = id

        // this.$refs.tree.setCheckedKeys([]);
        // if (this.conceptId === '' || this.conceptId === undefined || this.conceptId === null) {
        if (this.conceptId.length === 0) {
          this.$message.warning('请先在右侧树结构选择一条数据再进行映射！')
        } else {
          let obj = {}
          obj.code = row.code
          obj.codeSystemId = row.codeSystemId
          obj.domainId = row.domainId
          obj.conceptIds = this.conceptId
          let res = await bind(obj)
          if (res.code === 200) {
            this.$message.success(res.msg)
            this.getList(row.code)
            this.matchNeedDefFn()
          }
        }
      }

    },
    // // 单击某一行
    rowClick(row) {
      // console.log(row)
      this.currentLineTableData = row
      // 右侧赋值清空操作
      this.selectOrg.orgsid = []
      this.mappingList.forEach((item, index) => {
        item.isNeedMappinig = false
      })
      if (row.mappingStatus === '已映射' ) {
        this.$refs.tree.setCheckedKeys(row.mappingIdList);
        this.selectOrg.orgsid = row.mappingIdList
      } else if (row.mappingStatus === '未映射' ) {
        let id = row.id
        this.id = id
        this.$refs.tree.setCheckedKeys([]);
        this.selectOrg.orgsid = []
      }

    },
    // 获取当前右边选择list
    currentChecked (nodeObj, SelectedObj) {
      // console.log(SelectedObj)
      // console.log(SelectedObj.checkedKeys)   // 这是选中的节点的key数组
      // console.log(SelectedObj.checkedNodes)  // 这是选中的节点数组
      this.conceptId = SelectedObj.checkedKeys
      let isNeedMappinig = false
      if (this.selectOrg.orgsid.toString() == this.conceptId.toString()) {
        isNeedMappinig = false
      } else {
        if (this.conceptId.length>0){
          isNeedMappinig = true
        } else {
          isNeedMappinig = false
        }
      }
      this.mappingList.forEach((item, index) => {
        if (item.code === this.currentLineTableData.code) {
          item.isNeedMappinig = isNeedMappinig
          this.$set(this.mappingList, index, item)
        }
      })
    },
    handleCheckChange(data, checked, indeterminate) {
      // console.log(data, '数据')
      // console.log(checked, '选中状态')
      // console.log(indeterminate, '子树中选中状态')
      // 获取当前选择的id在数组中的索引
      const indexs = this.selectOrg.orgsid.indexOf(data.conceptId)
      // 如果不存在数组中，并且数组中已经有一个id并且checked为true的时候，代表不能再次选择。
      if (indexs < 0 && this.selectOrg.orgsid.length === 1 && checked) {
        // console.log('only one')
        this.$message({
          message: '只能选择一条数据，不能多选！',
          type: 'error',
          showClose: true
        })
        // 设置已选择的节点为false 很重要
        this.$refs.tree.setChecked(data, false)
      } else if (this.selectOrg.orgsid.length === 0 && checked) {
        // 发现数组为空 并且是已选择
        // 防止数组有值，首先清空，再push
        this.selectOrg.orgsid = []
        this.selectOrg.orgsid.push(data.conceptId)
      } else if (
        indexs >= 0 &&
        this.selectOrg.orgsid.length === 1 &&
        !checked
      ) {
        // 再次直接进行赋值为空操作
        this.selectOrg.orgsid = []
      }
      // console.log(this.selectOrg.orgsid)
      this.conceptId = this.selectOrg.orgsid[0]
      // console.log(this.conceptId)
    },
    handleElNodeClick(data, node) {
      // console.log(data, node)
    },
    async handleMappingClick() {
      if (this.id === '' || this.id === undefined || this.id === null) {
        this.$message.warning('请先选择一条机构数据再进行映射！')
      } else {
        let obj = {}
        obj.id = this.id
        obj.conceptId = this.conceptId
        let res = await bind(obj)
        if (res.code === 200) {
          this.$message.success(res.msg)
          this.getList()
        }
      }
    },
    // 取消映射
    async handleCancelMapping(row) {
      await this.headMessage()
      if (this.statusStr === '已驳回') {
        this.$message.warning('当前数据已被驳回，请返回并检查状态！')
      } else if (this.statusStr === '已撤回') {
        this.$message.warning('当前数据已被撤回，请返回并检查状态！')
      } else if (this.statusStr === '已终止') {
        this.$message.warning('当前数据已被终止，请返回并检查状态！')
      } else {
        if (!row.code) {
          return
        }

        this.$confirm('确定要取消'+ this.labelName + '-'  + row.code + '-' + row.name + '的映射吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async () => {
          let obj = {}
          obj.code = row.code
          obj.codeSystemId = row.codeSystemId
          obj.domainId = row.domainId
          let res = await unbind(obj)
          if (res.code === 200) {
            this.$message.success(res.msg)
            this.getList(row.code)
            this.matchNeedDefFn()
          }
        })
      }
    },
    handlerKeyChange($ev) {
      // console.log($ev)
      // console.log($ev.keyCode)
    },
    // 是否
    async matchNeedDefFn() {
      let obj = {}
      obj.orgId = this.orgId
      let res = await matchNeedDef(obj)
      if (res.code === 200) {
        this.isMatchNeedDef = res.data
        if (this.isMatchNeedDef === null) {
          let height = this.$refs.udpTable.offsetHeight
          this.tableHeight = window.innerHeight - 100 - 100 - 47.5
          this.conheight.height = window.innerHeight - 100 - 100 - 47.5 + 'px'
        } else {
          let height = this.$refs.udpTable.offsetHeight
          this.tableHeight = window.innerHeight - 100 - 100 - 47.5 - 25
          this.conheight.height = window.innerHeight - 100 - 100 - 47.5 - 25 + 'px'
        }
      }
    },
    // 获取一对多列表
    async getDefList() {
      this.isMatchLoading = true
      let obj = {}
      obj.orgId = this.orgId
      let res = await defList(obj)
      if (res.code === 200) {
        this.matchDefListOptions = res.data
        this.getIds(this.matchDefListOptions)
        this.matchDefDialogVisible = true
        this.isMatchLoading = false
        let contactDot = 0
        this.matchDefListOptions.forEach((item, index) => {
          this.spanArr[index] = item.length
        })
      }
    },
    // 获取选择ids数据
    getIds(data) {
      let ids = []
      data.forEach((item, index) => {
        item.forEach((i) => {
          if (i.orgDefFlag === 1) {
            ids[index] = i.id
          }
        })
      })
      this.ids = ids
    },
    matchDefHandleClose() {
      this.matchDefDialogVisible = false
    },
    failMatchDefCancel() {
      this.matchDefDialogVisible = false
      this.ids = []
    },
    async submitMatchDef() {
      let r = this.trimSpace(this.ids)
      let res = await setMatchDef(r)
      if (res.code === 200) {
        this.$message.success(res.msg)
        this.matchDefDialogVisible = false
        this.matchNeedDefFn()
      }
    },
    trimSpace(array){
      for(var i = 0 ;i<array.length;i++) {
        if(array[i] == " " || array[i] == null || typeof(array[i]) == "undefined") {
          array.splice(i,1);
          i= i-1;
        }
      }
      return array
    },
    clickChange (item) {
      console.log(item)
      // this.tableRadio = item.id
    },
    handleRadioChanges(val) {
      console.log(val)
      console.log(this.ids)
    },
    arraySpanMethod({ row, column, rowIndex, columnIndex },index) {
      console.log(index)
      console.log(row,column)
      let rowLength = 2
      if (columnIndex === 0) {
        const _row = this.spanArr[index]
        if (rowIndex % _row === 0) {
          return {
            rowspan: _row,
            colspan: 1
          }
        } else {
          return {
            rowspan: 0,
            colspan: 0
          }
        }
      }
    }
  },
  // beforeRouteEnter(to, from ,next) {
  //   console.log(to)
  //   const isLookShowHH = to.query && to.query.isLookShow
  //   if (isLookShowHH) {
  //     to.meta.title = '代码查看'
  //   } else {
  //     to.meta.title = '代码匹配'
  //   }
  //   next((vm) => {})
  // }
};
</script>
<style lang="scss" scoped>
.choiceTree{
  padding:10px;
}
.treeContent{ // 树结构盛满剩余高度
  //height: 400px;
  overflow-y: auto;
  //background-color: #cc85d9;
  width: 100%;
  position: absolute;
  top: 96px;
  bottom: 0px;
  right: 0px;
}
.headerClass{
  padding: 0;
  margin: 0;
  .headerLiClass{
    line-height: 36px;
    padding-right: 10px;
    display: inline-block;
  }
}
.drugElementData{
  margin-top: 10px;
  //margin-bottom:10px;
}
.setMapping{
  color:red;
  cursor:pointer;
  font-size: 14px;
  padding-bottom: 5px;
  display: inline-block;
  &:hover{
    color: #F56C6C;
    text-decoration:underline;
  }
}
.stateContent{
  span {
    //width: 213px;
    //height: 14px;
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #666666;
    line-height: 33px;
    margin-right: 10px;
    .stateResult{
      color: #E4860A;
      font-style: normal;
    }
    .uploadMethod{
      color: #333333;
      font-style: normal;
    }
  }
}
.menuChoice{
  padding:0;
  margin:0;
  display: block;
  width: 100%;
  background: #FFFFFF;
  height: 40px;
  li{
    display: inline-block;
    a{
      border-bottom: 2px solid #FFFFFF;
      display: inline-block;
      padding:0;
      margin:0;
      line-height: 40px;
      text-align: center;
      height: 40px;
      list-style: none;
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 400;
      //background: #FFFFFF;
      color: #333333;
      padding:0px 8px;
    }
  }
}
.menuLiActive a{
  border-bottom: 2px solid #2D5AFA!important;
  color:#2D5AFA!important;;
  //background: #E6ECFA!important;
}
.firstMenuChoice{
  padding:0;
  margin:0;
  display: block;
  width: 100%;
  background: #FFFFFF;
  height: 36px;
  li{
    display: inline-block;
    a{
      border: 1px solid #96B1FA;
      display: inline-block;
      padding:0;
      margin:0;
      text-align: center;
      width: 90px;
      height: 36px;
      line-height: 36px;
      list-style: none;
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 400;
      //background: #FFFFFF;
      color: #333333;
      padding:0px 15px;
    }
  }
}
.firstmenuLiActive a{
  border: 1px solid #2D5AFA!important;
  color:#2D5AFA!important;;
  background: #E6ECFA!important;
}
.thirdMenuChoice{
  padding:0;
  margin:0;
  display: block;
  width: 100%;
  background: #FFFFFF;
  height: 36px;
  li{
    display: inline-block;
    a{
      border: 1px solid #96B1FA;
      display: inline-block;
      padding:0;
      margin:0;
      text-align: center;
      width: 90px;
      height: 36px;
      line-height: 36px;
      list-style: none;
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 400;
      //background: #FFFFFF;
      color: #333333;
      padding:0px 15px;
    }
  }
}
.thirdmenuLiActive a{
  border: 1px solid #2D5AFA!important;
  color:#2D5AFA!important;;
  background: #E6ECFA!important;
}
.platformDkbWrap{
  border: 1px solid #dfe6ec;
  overflow-y: auto;
  .platformDkb{
    font-weight: bold;
    word-break: break-word;
    background-color: #E8EEFC;
    color: #333333;
    padding: 0px;
    padding-left: 10px;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    border-bottom: 1px solid #dfe6ec;
  }
}
.yys{
  display: inline-block;
  background: #D8E2FF;
  border-radius: 3px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #2B60F8;
  width: 58px;
  height: 26px;
  line-height: 26px;
}
.wys{
  display: inline-block;
  background: #DDDDDD;
  border-radius: 3px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #666666;
  width: 58px;
  height: 26px;
  line-height: 26px;
}
.searchUl{
  padding:0;
  margin: 0;
  li{
    list-style: none;
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #333333;
  }
  .searchLiLeft{
    display:block;
    float: left;
  }
  .searchLiRight{
    display:block;
    float: left;
  }
  .searchLiLeftBtn{
    display:block;
    float: left;
    margin:0px 5px 5px 0px;
  }
}
.bottomWrap{
  height: 550px;
  margin-top: 10px;
  .bottomLeft{
    padding:0;
    background: #ffffff;
    border: 1px solid #CFDBFB;
    height: 570px;
    margin-right: 10px;
    .el-header{
      padding:0px 10px!important;
      height: 40px!important;
      line-height: 40px!important;
      background: #E8EEFC;
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 500;
    }
    .el-form{
      margin-top: 20px;
    }
    .el-form-item{
      margin-bottom: 10px;
    }
  }
  .bottomRight{
    padding:0;
    height: 550px;
    .el-header{
      padding:0px!important;
      height: 85px!important;
      .el-form-item{
        margin-bottom: 0px!important;
      }
    }
    .el-main{
      padding:0px!important;
      .mappingBottomRight{
        margin-top: 10px;
        .el-aside{
          padding:0;
          background: #ffffff;
          border: 1px solid #CFDBFB;
          margin:0px!important;
          margin-right: 10px;
          .el-header{
            padding:0px 10px!important;
            height: 40px!important;
            line-height: 40px!important;
            background: #E8EEFC;
            font-size: 14px;
            font-family: PingFang SC;
            font-weight: 500;
          }
          .el-form{
            margin: 13px 0px;
            padding-right:10px;
          }
          .el-form-item{
            margin-bottom: 10px;
          }
        }
        .remarkWrap{
          .remarkForm{
            .el-form-item{
              margin-bottom: 5px!important;
            }
            .el-checkbox{
              width:80px!important;
            }
          }
        }
      }
    }
    //background:#BDD3FF;
  }
}
// 区分大小屏
@media screen and (max-width: 1440px) {
  .bottomWrap{
    height: 450px;
    // 表格高度缩小
    .bottomLeft{
      height: 450px;
      .el-main{
        padding:0px 10px 0px 0px!important;
      }
      .el-form{
        margin-top:5px!important;
      }
      .el-form-item{
        height: 30px!important;
        line-height: 30px!important;
        margin-bottom: 6px!important;
      }
      >>>.el-input__inner{
        height: 30px!important;
        line-height: 30px!important;
      }
      .el-input{
        height: 30px!important;
        line-height: 30px!important;
      }
    }
    .mappingBottomRight{
      .remarkWrap{
        height: 300px;
        .el-form-item{
          margin-bottom: 5px;
          height: 30px!important;
          line-height: 30px!important;
        }
        .el-input__inner{
          height: 30px!important;
          line-height: 30px!important;
        }
        .el-input{
          height: 30px!important;
          line-height: 30px!important;
        }
        .remarkForm{
          .el-form-item{
            margin-bottom: 3px!important;
          }
        }
      }
    }
  }

}

</style>
<style lang="scss">
#mappingWrap .el-dialog__body {
  padding:0!important;
  .approvalWrapTop{
    padding:10px 20px 10px 20px;
  }
  .approvalWrapBottom{
    padding:10px 0px;
    border-top: 1px solid #cccccc;
  }
}
// 区分大小屏
@media screen and (max-width: 1440px){
  #mappingWrap{
    // 表格高度缩小
    .bottomLeft{
      .el-input__inner{
        height: 30px!important;
        line-height: 30px!important;
      }
    }
    .bottomRight{
      .el-input__inner{
        height: 30px!important;
        line-height: 30px!important;
      }
    }
  }
}
</style>
