var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container", attrs: { id: "mappingWrap" } },
    [
      _c("ul", { staticClass: "headerClass" }, [
        _vm.firstFlag === 0
          ? _c("li", { staticClass: "headerLiClass" }, [
              _c(
                "ul",
                { staticClass: "menuChoice" },
                _vm._l(_vm.menulist, function (item, index) {
                  return _c(
                    "li",
                    {
                      key: index,
                      class: _vm.flag == index ? "menuLiActive" : "",
                      on: {
                        click: function ($event) {
                          return _vm.chooseMenu(index)
                        },
                      },
                    },
                    [
                      _c("a", { attrs: { href: item.href } }, [
                        _vm._v(_vm._s(item.name)),
                      ]),
                    ]
                  )
                }),
                0
              ),
            ])
          : _vm._e(),
      ]),
      _vm.firstFlag === 0
        ? _c(
            "div",
            [
              _c("ul", { staticClass: "headerClass drugElementData" }, [
                _vm.firstFlag === 0
                  ? _c(
                      "li",
                      { staticClass: "headerLiClass" },
                      [
                        _c(
                          "el-form",
                          {
                            ref: "queryForm",
                            attrs: { model: _vm.queryParams, inline: true },
                          },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "", prop: "searchValue" } },
                              [
                                _c("el-input", {
                                  staticStyle: {
                                    width: "200px",
                                    height: "36px!important",
                                    "line-height": "36px",
                                  },
                                  attrs: {
                                    placeholder: "输入类型代码/类型名称",
                                    clearable: "",
                                  },
                                  nativeOn: {
                                    keyup: function ($event) {
                                      return _vm.handlerKeyChange($event)
                                    },
                                  },
                                  model: {
                                    value: _vm.queryParams.searchValue,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.queryParams,
                                        "searchValue",
                                        $$v
                                      )
                                    },
                                    expression: "queryParams.searchValue",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      type: "primary",
                                      icon: "el-icon-search",
                                      size: "small",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleQuery()
                                      },
                                    },
                                  },
                                  [_vm._v("查询")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _c("li", { staticClass: "headerLiClass" }, [
                  _c(
                    "ul",
                    { staticClass: "thirdMenuChoice" },
                    _vm._l(_vm.thirdMenulist, function (item, index) {
                      return _c(
                        "li",
                        {
                          key: index,
                          class:
                            _vm.thirdFlag == index ? "thirdmenuLiActive" : "",
                          on: {
                            click: function ($event) {
                              return _vm.chooseThirdMenu(index)
                            },
                          },
                        },
                        [
                          _c("a", { attrs: { href: item.href } }, [
                            _vm._v(_vm._s(item.name)),
                          ]),
                        ]
                      )
                    }),
                    0
                  ),
                ]),
              ]),
              this.isMatchNeedDef !== null
                ? _c(
                    "section",
                    { staticStyle: { "margin-bottom": "10px" } },
                    [
                      _c(
                        "el-link",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: this.isMatchNeedDef === true,
                              expression: "this.isMatchNeedDef === true",
                            },
                            {
                              name: "loading",
                              rawName: "v-loading.fullscreen.lock",
                              value: _vm.isMatchLoading,
                              expression: "isMatchLoading",
                              modifiers: { fullscreen: true, lock: true },
                            },
                          ],
                          attrs: { type: "danger" },
                          on: { click: _vm.getDefList },
                        },
                        [
                          _vm._v(
                            " 当前上传数据存在多对一的映射状态，请选择默认映射值 "
                          ),
                        ]
                      ),
                      _c(
                        "el-link",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: this.isMatchNeedDef === false,
                              expression: "this.isMatchNeedDef === false",
                            },
                            {
                              name: "loading",
                              rawName: "v-loading.fullscreen.lock",
                              value: _vm.isMatchLoading,
                              expression: "isMatchLoading",
                              modifiers: { fullscreen: true, lock: true },
                            },
                          ],
                          attrs: { type: "primary" },
                          on: { click: _vm.getDefList },
                        },
                        [_vm._v(" 维护映射默认值 ")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 18 } },
                    [
                      _c(
                        "el-table",
                        {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value: _vm.loading,
                              expression: "loading",
                            },
                          ],
                          ref: "udpTable",
                          attrs: {
                            border: "",
                            stripe: "",
                            height: _vm.tableHeight,
                            "header-cell-style": { "text-align": "center" },
                            "cell-style": { "text-align": "center" },
                            data: _vm.mappingList,
                            "highlight-current-row": "",
                          },
                          on: { "row-click": _vm.rowClick },
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              prop: "code",
                              label: _vm.labelName + "-类型代码",
                              width: "180",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "name",
                              label: _vm.labelName + "-类型名称",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: { label: "映射状态" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      scope.row.mappingStatus === "已映射"
                                        ? _c("span", { staticClass: "yys" }, [
                                            _vm._v(
                                              _vm._s(scope.row.mappingStatus)
                                            ),
                                          ])
                                        : scope.row.mappingStatus === "未映射"
                                        ? _c("span", { staticClass: "wys" }, [
                                            _vm._v(
                                              _vm._s(scope.row.mappingStatus)
                                            ),
                                          ])
                                        : _vm._e(),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              542255893
                            ),
                          }),
                          _c("el-table-column", {
                            attrs: { prop: "mappingIdStr", label: "映射ID" },
                          }),
                          _c("el-table-column", {
                            attrs: { prop: "mappingName", label: "映射值" },
                          }),
                          _c("el-table-column", {
                            attrs: { label: "操作" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      scope.row.mappingStatus === "未映射" ||
                                      scope.row.isNeedMappinig === true
                                        ? _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                size: "mini",
                                                type: "text",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  $event.stopPropagation()
                                                  return _vm.handleMapping(
                                                    scope.row
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("映射")]
                                          )
                                        : _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                size: "mini",
                                                type: "text",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  $event.stopPropagation()
                                                  return _vm.handleCancelMapping(
                                                    scope.row
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("取消映射")]
                                          ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              249435185
                            ),
                          }),
                          _vm.isResult === false
                            ? _c("template", { slot: "empty" }, [
                                _c("img", {
                                  attrs: {
                                    src: require("@/assets/images/no-booking.svg"),
                                    alt: "",
                                  },
                                }),
                                _c("p", [_vm._v("暂无数据")]),
                              ])
                            : _c("template", { slot: "empty" }, [
                                _c("img", {
                                  attrs: {
                                    src: require("@/assets/images/no-left-data.svg"),
                                    alt: "",
                                  },
                                }),
                                _c("p", [
                                  _vm._v("未查询到结果，请修改查询条件重试！"),
                                ]),
                              ]),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-row",
                        {
                          staticClass: "platformDkbWrap",
                          style: _vm.conheight,
                        },
                        [
                          _c("el-col", { staticClass: "platformDkb" }, [
                            _vm._v("平台药品知识库"),
                          ]),
                          _c("el-col", { staticClass: "platformDkb_content" }, [
                            _c(
                              "div",
                              { staticClass: "choiceTree" },
                              [
                                _c(
                                  "el-input",
                                  {
                                    attrs: { placeholder: "请输入内容" },
                                    model: {
                                      value: _vm.filterText,
                                      callback: function ($$v) {
                                        _vm.filterText = $$v
                                      },
                                      expression: "filterText",
                                    },
                                  },
                                  [
                                    _c("i", {
                                      staticClass:
                                        "el-input__icon el-icon-search",
                                      attrs: { slot: "prefix" },
                                      slot: "prefix",
                                    }),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "treeContent" },
                              [
                                _c("el-tree", {
                                  ref: "tree",
                                  attrs: {
                                    data: _vm.cwMedicineIdentification,
                                    "show-checkbox": "",
                                    "node-key": "conceptId",
                                    "empty-text": "暂无数据！",
                                    props: _vm.defaultProps,
                                    "check-strictly": "",
                                    "filter-node-method": _vm.filterNode,
                                  },
                                  on: {
                                    "node-click": _vm.handleElNodeClick,
                                    check: _vm.currentChecked,
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    {
                      staticClass: "stateContent",
                      staticStyle: { "padding-top": "10px" },
                      attrs: { span: 12 },
                    },
                    [
                      _c("span", [
                        _vm._v("上传时间：" + _vm._s(_vm.uploadTime)),
                      ]),
                      _c("span", [
                        _vm._v("状态："),
                        _c("i", { staticClass: "stateResult" }, [
                          _vm._v(_vm._s(_vm.statusStr)),
                        ]),
                      ]),
                      _c("span", [
                        _vm._v("上传方式："),
                        _c("i", { staticClass: "uploadMethod" }, [
                          _vm._v(_vm._s(_vm.uploadTypeStr)),
                        ]),
                      ]),
                    ]
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c("pagination", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.total > 0,
                            expression: "total>0",
                          },
                        ],
                        attrs: {
                          total: _vm.total,
                          page: _vm.queryParams.pageNum,
                          limit: _vm.queryParams.pageSize,
                          isSmall: true,
                        },
                        on: {
                          "update:page": function ($event) {
                            return _vm.$set(_vm.queryParams, "pageNum", $event)
                          },
                          "update:limit": function ($event) {
                            return _vm.$set(_vm.queryParams, "pageSize", $event)
                          },
                          pagination: _vm.getList,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.matchDefDialogVisible,
            title: "默认映射值设置",
            "close-on-press-escape": false,
            "close-on-click-modal": false,
            width: "80%",
            "before-close": _vm.matchDefHandleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.matchDefDialogVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "approvalWrapTop" },
            _vm._l(_vm.matchDefListOptions, function (item, index) {
              return _c(
                "div",
                [
                  _c(
                    "el-radio-group",
                    {
                      staticStyle: { width: "100%" },
                      on: { change: _vm.handleRadioChanges },
                      model: {
                        value: _vm.ids[index],
                        callback: function ($$v) {
                          _vm.$set(_vm.ids, index, $$v)
                        },
                        expression: "ids[index]",
                      },
                    },
                    [
                      _c(
                        "el-table",
                        {
                          ref: "commnonName",
                          refInFor: true,
                          staticStyle: { margin: "10px 0px" },
                          attrs: {
                            data: item,
                            border: "",
                            stripe: "",
                            "header-cell-style": { "text-align": "center" },
                            "cell-style": { "text-align": "center" },
                            "span-method": function (param) {
                              return _vm.arraySpanMethod(param, index)
                            },
                          },
                          on: { "current-change": _vm.clickChange },
                        },
                        [
                          _c("el-table-column", {
                            attrs: { prop: "domainName", label: "类型" },
                          }),
                          _c("el-table-column", {
                            attrs: { prop: "code", label: "代码" },
                          }),
                          _c("el-table-column", {
                            attrs: { prop: "name", label: "名称" },
                          }),
                          _c("el-table-column", {
                            attrs: { label: "映射状态" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      scope.row.mappingStatus === "已映射"
                                        ? _c("span", { staticClass: "yys" }, [
                                            _vm._v(
                                              _vm._s(scope.row.mappingStatus)
                                            ),
                                          ])
                                        : scope.row.mappingStatus === "未映射"
                                        ? _c("span", { staticClass: "wys" }, [
                                            _vm._v(
                                              _vm._s(scope.row.mappingStatus)
                                            ),
                                          ])
                                        : _vm._e(),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          }),
                          _c("el-table-column", {
                            attrs: { prop: "mappingIdStr", label: "映射ID" },
                          }),
                          _c("el-table-column", {
                            attrs: { prop: "mappingName", label: "映射值" },
                          }),
                          _c("el-table-column", {
                            attrs: { label: "设为默认值" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "el-radio",
                                        { attrs: { label: scope.row.id } },
                                        [_c("i")]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            }),
            0
          ),
          _c(
            "el-row",
            { staticClass: "approvalWrapBottom" },
            [
              _c(
                "el-col",
                {
                  staticStyle: { "text-align": "center" },
                  attrs: { span: 24 },
                },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "successClass",
                      attrs: { type: "primary" },
                      on: { click: _vm.submitMatchDef },
                    },
                    [_vm._v("确定")]
                  ),
                  _c(
                    "el-button",
                    {
                      staticClass: "failClass",
                      on: { click: _vm.failMatchDefCancel },
                    },
                    [_vm._v("取消")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }